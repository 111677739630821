import axios from 'axios'
import type { AxiosError } from 'axios'
import { HttpStatusCode } from 'axios'
import { captureException } from '@sentry/vue'

export default defineNuxtRouteMiddleware(async () => {
  const { $client } = useNuxtApp()
  // NOTE: レスポンスの受信後に行いたい処理
  axios.interceptors.response.use(
    res => {
      return res
    },
    // NOTE: レスポンスエラーをキャッチしたときに行いたい処理
    async (err: AxiosError) => {
      if (err.response) {
        // NOTE: リクエストは行われたが、サーバーから2xxの範囲外のステータスコードが返ってきた場合

        if (err.response.status === HttpStatusCode.ServiceUnavailable) {
          const data = err.response.data as { title: string; message: string }
          showError({
            statusCode: 503,
            statusMessage: data?.title || 'メンテナンス中です',
            message: data?.message || '',
          })
        }

        if (err.response.status === HttpStatusCode.Unauthorized) {
          /*
           * NOTE: Unauthorized ErrorはSentryへ送信しない
           * 経緯: v4/auth/me は未ログイン時だと必ず401を返すのでsentryへの通知が毎回送られてしまう
           */
          await $client.v3.refresh_token.post()
          return Promise.reject(err)
        }

        captureException(err.response)
      } else if (err.request) {
        /*
         * NOTE: リクエストは行われたが応答がない場合
         * XMLHttpRequest: ブラウザのインスタンス
         * http.ClientRequest: nodejsのインスタンス
         * ※APIへ通信に失敗している段階なのでSentryでキャッチしない
         */

        throw new Error(err.request)
      } else {
        // NOTE: リクエストのセットアップ中にエラーが発生した場合

        captureException(err.message)
      }

      captureException(err)
      return Promise.reject(err)
    },
  )
})
